<backbone>
  <section header-page="topbar" class="d-flex h-100 position-relative">
    <div class="w-50 d-flex justify-content-start align-items-center">
      <p class="m-0 fw-medium">Tarifas de precios por zonas</p>
    </div>

    <div class="w-50 d-flex justify-content-end align-items-center gap-3">
      <breadcrumb
        [links]="[{title: 'Dashboard'}, {title: 'Transportación', link: '/transportation'}, {title: 'Tarifas'}]" />

      <p-button (onClick)="toggleDialogZoneRate('show', 'Crear una nueva tarifa por zona')" label="Nuevo"
        size="small" />
    </div>
  </section>

  @if (this.isLoading === true) {
  <section wrapper>
    <div class="page-section card">
      <div class="card-body p-0">
        <p-table [value]="[1,2,3,4,5]" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th>Rango de zonas</th>
              <th>
                <span class="d-block"><small>Precio</small></span>
                <span class="d-block">Base</span>
              </th>
              <th>
                <span class="d-block"><small>Precio</small></span>
                <span class="d-block">Taxi</span>
              </th>
              <th>
                <span class="d-block"><small>Precio</small></span>
                <span class="d-block">Privado económico</span>
              </th>
              <th>
                <span class="d-block"><small>Precio</small></span>
                <span class="d-block">Privado en camioneta</span>
              </th>
              <th>
                <span class="d-block"><small>Precio</small></span>
                <span class="d-block">Privado de lujo</span>
              </th>
              <th>
                <span class="d-block"><small>Precio</small></span>
                <span class="d-block">Privado para grupo</span>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr>
              <td><p-skeleton /></td>
              <td><p-skeleton /></td>
              <td><p-skeleton /></td>
              <td><p-skeleton /></td>
              <td><p-skeleton /></td>
              <td><p-skeleton /></td>
              <td><p-skeleton /></td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </section>
  }

  @else {
  <section wrapper>
    <div class="page-section card">
      <div class="card-body p-0">
        <p-table #dt1 [value]="zonesRateList" dataKey="transportationZoneRateId" [rows]="25"
          [rowsPerPageOptions]="[25, 50, 100]" [paginator]="true">

          <ng-template pTemplate="header">
            <tr>
              <th>Rango de zonas</th>
              <th>
                <span class="d-block"><small>Precio</small></span>
                <span class="d-block">Base</span>
              </th>
              <th>
                <span class="d-block"><small>Precio</small></span>
                <span class="d-block">Taxi</span>
              </th>
              <th>
                <span class="d-block"><small>Precio</small></span>
                <span class="d-block">Privado económico</span>
              </th>
              <th>
                <span class="d-block"><small>Precio</small></span>
                <span class="d-block">Privado en camioneta</span>
              </th>
              <th>
                <span class="d-block"><small>Precio</small></span>
                <span class="d-block">Privado de lujo</span>
              </th>
              <th colspan="2">
                <span class="d-block"><small>Precio</small></span>
                <span class="d-block">Privado para grupo</span>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td class="text-nowrap">{{getZoneName(item.departureId)}} - {{getZoneName(item.destinationId)}}</td>
              <td class="text-nowrap">
                <span class="d-block">ONEWAY - {{item.basePrice.ONEWAY | currency: 'MXN'}}</span>
                <span class="d-block">ROUNDTRIP - {{item.basePrice.ROUNDTRIP | currency: 'MXN'}}</span>
              </td>
              <td class="text-nowrap">
                @if (item.category1Price !== null) {
                <span class="d-block">ONEWAY - {{item.category1Price?.ONEWAY | currency: 'MXN'}}</span>
                <span class="d-block">ROUNDTRIP - {{item.category1Price?.ROUNDTRIP | currency: 'MXN'}}</span>
                }
              </td>
              <td class="text-nowrap">
                @if (item.category2Price !== null) {
                <span class="d-block">ONEWAY - {{item.category2Price?.ONEWAY | currency: 'MXN'}}</span>
                <span class="d-block">ROUNDTRIP - {{item.category2Price?.ROUNDTRIP | currency: 'MXN'}}</span>
                }
              </td>
              <td class="text-nowrap">
                @if (item.category3Price !== null) {
                <span class="d-block">ONEWAY - {{item.category3Price?.ONEWAY | currency: 'MXN'}}</span>
                <span class="d-block">ROUNDTRIP - {{item.category3Price?.ROUNDTRIP | currency: 'MXN'}}</span>
                }
              </td>
              <td class="text-nowrap">
                @if (item.category4Price !== null) {
                <span class="d-block">ONEWAY - {{item.category4Price?.ONEWAY | currency: 'MXN'}}</span>
                <span class="d-block">ROUNDTRIP - {{item.category4Price?.ROUNDTRIP | currency: 'MXN'}}</span>
                }
              </td>
              <td class="text-nowrap">
                @if (item.category5Price !== null) {
                <span class="d-block">ONEWAY - {{item.category5Price?.ONEWAY | currency: 'MXN'}}</span>
                <span class="d-block">ROUNDTRIP - {{item.category5Price?.ROUNDTRIP | currency: 'MXN'}}</span>
                }
              </td>
              <td class="text-nowrap">
                <div class="d-flex justify-content-end">
                  <p-button icon="pi pi-pencil" severity="warning" [text]="true"
                    (onClick)="toggleDialogZoneRate('show', 'Actualizar tarifa: ' + getZoneName(item.departureId) + ' - ' + getZoneName(item.destinationId), 'modify', item)" />
                  <p-button icon="pi pi-trash" severity="danger" [text]="true"
                    (onClick)="deleteDialogZoneRate($event, item.transportationZoneRateId)" />
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td class="text-center opacity-50" colspan="7">No hay tarifas</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </section>
  }
</backbone>

<p-dialog [formGroup]="formCreateZoneRate" [header]="dialogZoneRate.title" [modal]="true"
  [(visible)]="dialogZoneRate.visible" [position]="'top'" [draggable]="false" [style]="{ width: '50rem' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" (onHide)="toggleDialogZoneRate('hide')">
  <div class="py-4">
    <p class="mb-2">Selecciona la zona de partida del punto A, a la zona destino punto B.</p>
    <div class="row">
      <div class="col-lg-6">
        <div class="d-flex flex-column gap-2 mb-3">
          <p-dropdown formControlName="departure" [options]="zonesList" appendTo="body" optionLabel="label"
            optionValue="transportationZoneId" showClear="false" [filter]="true" filterBy="label"
            placeholder="Selecciona una zona" emptyMessage="No hay resultados"
            emptyFilterMessage="No se encontraron resultados" styleClass="w-100 h-50px">
            <ng-template pTemplate="selectedItem" let-selectedOption>
              <div>{{ selectedOption.label }}</div>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <div>{{ item.label }}</div>
            </ng-template>
          </p-dropdown>
          <small class="ps-2">Zona de partida. <span class="text-danger">Requerido *</span></small>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="d-flex flex-column gap-2 mb-3">
          <p-dropdown formControlName="destination" [options]="zonesList" appendTo="body" optionLabel="label"
            optionValue="transportationZoneId" showClear="false" [filter]="true" filterBy="label"
            placeholder="Selecciona una zona" emptyMessage="No hay resultados"
            emptyFilterMessage="No se encontraron resultados" styleClass="w-100 h-50px">
            <ng-template pTemplate="selectedItem" let-selectedOption>
              <div>{{ selectedOption.label }}</div>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <div>{{ item.label }}</div>
            </ng-template>
          </p-dropdown>
          <small class="ps-2">Zona de destino. <span class="text-danger">Requerido *</span></small>
        </div>
      </div>
    </div>

    <hr>

    <p class="mb-2">Precio base.</p>
    <div class="row">
      <div class="col-lg-6">
        <div class="d-flex flex-column gap-2 mb-3">
          <p-inputNumber formControlName="priceBaseOneway" mode="currency" currency="MXN" styleClass="w-100 h-50px" />
          <small class="ps-2">ONEWAY. <span class="text-danger">Requerido *</span></small>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="d-flex flex-column gap-2 mb-3">
          <p-inputNumber formControlName="priceBaseRoundtrip" mode="currency" currency="MXN"
            styleClass="w-100 h-50px" />
          <small class="ps-2">ROUNDTRIP. <span class="text-danger">Requerido *</span></small>
        </div>
      </div>
    </div>

    <p class="mb-2">Taxi. <small class="d-block">Si se asigna un precio a esta categoría, debe
        asignar un precio a las dos opciones.</small></p>
    <div class="row">
      <div class="col-lg-6">
        <div class="d-flex flex-column gap-2 mb-3">
          <p-inputNumber formControlName="priceCategory1Oneway" mode="currency" currency="MXN"
            styleClass="w-100 h-50px" />
          <small class="ps-2">ONEWAY.</small>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="d-flex flex-column gap-2 mb-3">
          <p-inputNumber formControlName="priceCategory1Roundtrip" mode="currency" currency="MXN"
            styleClass="w-100 h-50px" />
          <small class="ps-2">ROUNDTRIP.</small>
        </div>
      </div>
    </div>

    <p class="mb-2">Privado económico. <small class="d-block">Si se asigna un precio a esta
        categoría, debe asignar un precio a las dos opciones.</small></p>
    <div class="row">
      <div class="col-lg-6">
        <div class="d-flex flex-column gap-2 mb-3">
          <p-inputNumber formControlName="priceCategory2Oneway" mode="currency" currency="MXN"
            styleClass="w-100 h-50px" />
          <small class="ps-2">ONEWAY.</small>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="d-flex flex-column gap-2 mb-3">
          <p-inputNumber formControlName="priceCategory2Roundtrip" mode="currency" currency="MXN"
            styleClass="w-100 h-50px" />
          <small class="ps-2">ROUNDTRIP.</small>
        </div>
      </div>
    </div>

    <p class="mb-2">Privado en camioneta. <small class="d-block">Si se asigna un precio a esta
        categoría, debe asignar un precio a las dos opciones.</small></p>
    <div class="row">
      <div class="col-lg-6">
        <div class="d-flex flex-column gap-2 mb-3">
          <p-inputNumber formControlName="priceCategory3Oneway" mode="currency" currency="MXN"
            styleClass="w-100 h-50px" />
          <small class="ps-2">ONEWAY.</small>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="d-flex flex-column gap-2 mb-3">
          <p-inputNumber formControlName="priceCategory3Roundtrip" mode="currency" currency="MXN"
            styleClass="w-100 h-50px" />
          <small class="ps-2">ROUNDTRIP.</small>
        </div>
      </div>
    </div>

    <p class="mb-2">Privado de lujo. <small class="d-block">Si se asigna un precio a esta
        categoría, debe asignar un precio a las dos opciones.</small></p>
    <div class="row">
      <div class="col-lg-6">
        <div class="d-flex flex-column gap-2 mb-3">
          <p-inputNumber formControlName="priceCategory4Oneway" mode="currency" currency="MXN"
            styleClass="w-100 h-50px" />
          <small class="ps-2">ONEWAY.</small>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="d-flex flex-column gap-2 mb-3">
          <p-inputNumber formControlName="priceCategory4Roundtrip" mode="currency" currency="MXN"
            styleClass="w-100 h-50px" />
          <small class="ps-2">ROUNDTRIP.</small>
        </div>
      </div>
    </div>

    <p class="mb-2">Privado para grupo. <small class="d-block">Si se asigna un precio a esta
        categoría, debe asignar un precio a las dos opciones.</small></p>
    <div class="row">
      <div class="col-lg-6">
        <div class="d-flex flex-column gap-2 mb-3">
          <p-inputNumber formControlName="priceCategory5Oneway" mode="currency" currency="MXN"
            styleClass="w-100 h-50px" />
          <small class="ps-2">ONEWAY.</small>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="d-flex flex-column gap-2 mb-3">
          <p-inputNumber formControlName="priceCategory5Roundtrip" mode="currency" currency="MXN"
            styleClass="w-100 h-50px" />
          <small class="ps-2">ROUNDTRIP.</small>
        </div>
      </div>
    </div>

    <hr>

    <p class="mb-2">Vehículos asignados.</p>
    <div class="row">
      <div class="col-lg-8">
        <div class="d-flex flex-column gap-2 mb-3">
          <p-dropdown [(ngModel)]="this.vehicleSelected" [ngModelOptions]="{standalone: true}" [options]="vehiclesList"
            appendTo="body" optionLabel="label" optionValue="transportationVehicleId" showClear="false" [filter]="true"
            filterBy="label" placeholder="Selecciona un vehículo" emptyMessage="No hay resultados"
            emptyFilterMessage="No se encontraron resultados" styleClass="w-100 h-50px">
            <ng-template pTemplate="selectedItem" let-selectedOption>
              <div>
                <span class="d-block">{{ selectedOption.label }}</span>
                <span class="d-block"><small class="opacity-50">{{selectedOption.brand}} - {{selectedOption.model}}
                    ({{selectedOption.year}}) / {{selectedOption.passengers}} pasajeros -
                    {{selectedOption.categoryPriceDescription}}</small></span>
              </div>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <div>
                <span class="d-block">{{ item.label }}</span>
                <span class="d-block"><small class="opacity-50">{{item.brand}} - {{item.model}} ({{item.year}}) /
                    {{item.passengers}} pasajeros - {{item.categoryPriceDescription}}</small></span>
              </div>
            </ng-template>
          </p-dropdown>
          <small class="ps-2">Lista de vehículos.</small>
        </div>
      </div>
      <div class="col-lg-4">
        <p-button label="Agregar" severity="secondary" styleClass="h-50px w-100" [disabled]="!vehicleSelected"
          (onClick)="addVehicleSelected(); this.vehicleSelected = null" />
      </div>
    </div>

    <div formArrayName="vehicles" class="d-flex flex-wrap gap-3">
      @for (item of vehicles.controls; track item; let index = $index) {
      <div class="d-flex align-items-center gap-3 w-100 justify-content-between">
        <p class="m-0">
          <span>{{getVehicle(item.value.vehicleId).label}}</span>
          <span class="opacity-50 mx-2">-</span>
          <span class="opacity-50">{{getVehicle(item.value.vehicleId).brand}} -
            {{getVehicle(item.value.vehicleId).model}} ({{getVehicle(item.value.vehicleId).year}}) /
            {{getVehicle(item.value.vehicleId).passengers}} pasajeros -
            {{getVehicle(item.value.vehicleId).categoryPriceDescription}}.</span>
        </p>

        <p-button icon="pi pi-trash" severity="danger" size="small" styleClass="rounded-2"
          (click)="deleteAssignedVehicle(index)"></p-button>
      </div>
      }
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button label="Cancelar" [text]="true" severity="primary" (onClick)="toggleDialogZoneRate('hide')" />
    <p-button label="Guardar" severity="primary" [disabled]="!formCreateZoneRate.valid" [loading]="onSaveZoneRate"
      (onClick)="saveDialogZoneRate()" />
  </ng-template>
</p-dialog>