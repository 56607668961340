<backbone>
  <section header-page="topbar" class="d-flex h-100 position-relative">
    <div class="w-50 d-flex justify-content-start align-items-center">
      <p class="m-0 fw-medium">Lista de vehículos</p>
    </div>

    <div class="w-50 d-flex justify-content-end align-items-center gap-3">
      <breadcrumb
        [links]="[{title: 'Dashboard'}, {title: 'Transportación', link: '/transportation'}, {title: 'Vehículos'}]" />

      <a class="btn btn-primary" routerLink="create"><small>Nuevo</small></a>
    </div>
  </section>

  @if (this.isLoading === true) {
  <section wrapper>
    <div class="page-section card">
      <div class="card-body p-0">
        <p-table [value]="[1,2,3,4,5]" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th>UUID</th>
              <th>Nombre</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr>
              <td><p-skeleton /></td>
              <td><p-skeleton /></td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </section>
  }

  @else {
  <section wrapper>
    <div class="page-section card">
      <div class="card-body p-0">
        <p-table #dt1 [value]="this.vehiclesList" dataKey="id" [rows]="25" [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[25, 50, 100]" [paginator]="true">
          <ng-template pTemplate="header">
            <tr>
              <th>UUID</th>
              <th colspan="3">Nombre</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td class="text-nowrap" style="width: 100px;"><small>{{item.uuid}}</small></td>
              <td class="text-nowrap" style="width: 100px;">
                <a routerLink="details/{{item.uuid}}">{{item.label}}</a>
                <small class="d-block">
                  {{item.categoryPriceDescription}}
                </small>
              </td>
              <td class="text-nowrap">
                {{item.brand}} - {{item.model}} ({{item.year}}) / {{item.passengers}} pasajeros
              </td>
              <td class="text-nowrap">
                <div class="d-flex justify-content-end">
                  <p-button icon="pi pi-trash" severity="danger" [text]="true"
                    (onClick)="delete(item.transportationVehicleId)" />
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td class="text-center opacity-50" colspan="5">No hay vehículos</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </section>
  }
</backbone>