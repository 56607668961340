import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { PaymentService } from '@services/payment.service';
import { TransportationService } from '@services/transportation.service';
import { ToastService } from '@utils/toast.service';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SkeletonModule } from 'primeng/skeleton';
import { TimelineModule } from 'primeng/timeline';

@Component({
  selector: 'app-transportation-booking-details',
  standalone: true,
  imports: [
    BackboneComponent,
    BreadcrumbComponent,
    ButtonModule,
    CurrencyPipe,
    CommonModule,
    InputTextModule,
    ReactiveFormsModule,
    CalendarModule,
    DropdownModule,
    InputTextareaModule,
    SkeletonModule,
    TimelineModule
  ],
  templateUrl: './details.component.html',
  styleUrl: './details.component.scss'
})
export class TransportationBookingDetailsPage {
  private activatedRoute = inject(ActivatedRoute);
  private titleService = inject(Title);
  private toastService = inject(ToastService);
  private router = inject(Router);
  private transportationService = inject(TransportationService);
  private paymentService = inject(PaymentService);
  private formBuilder = inject(FormBuilder);

  public isLoading = false;
  public isSaving = false;

  public booking: any
  public payment: any
  public yesOrNot: any = [
    { label: 'No', value: false },
    { label: 'Si', value: true }
  ]

  public form = this.formBuilder.group({
    name: [null],
    lastname: [null],
    email: [null],
    phone: [null],
    country: [null],
    city: [null],
    arrivalDate: [null],
    departureDate: [null],
    flightArrivalName: [null],
    flightArrivalNumber: [null],
    flightDepartureName: [null],
    flightDepartureNumber: [null],
    childSeat: [false],
    voluminousEquipment: [false],
    specialInstructions: [null],
  });

  get inputName() { return this.form.get('name') }
  set inputName(value: any) { this.inputName.setValue(value) }
  get inputLastname() { return this.form.get('lastname') }
  set inputLastname(value: any) { this.inputLastname.setValue(value) }
  get inputEmail() { return this.form.get('email') }
  set inputEmail(value: any) { this.inputEmail.setValue(value) }
  get inputPhone() { return this.form.get('phone') }
  set inputPhone(value: any) { this.inputPhone.setValue(value) }
  get inputCountry() { return this.form.get('country') }
  set inputCountry(value: any) { this.inputCountry.setValue(value) }
  get inputCity() { return this.form.get('city') }
  set inputCity(value: any) { this.inputCity.setValue(value) }
  get inputArrivalDate() { return this.form.get('arrivalDate') }
  set inputArrivalDate(value: any) { this.inputArrivalDate.setValue(value) }
  get inputDepartureDate() { return this.form.get('departureDate') }
  set inputDepartureDate(value: any) { this.inputDepartureDate.setValue(value) }
  get inputFlightArrivalName() { return this.form.get('flightArrivalName') }
  set inputFlightArrivalName(value: any) { this.inputFlightArrivalName.setValue(value) }
  get inputFlightArrivalNumber() { return this.form.get('flightArrivalNumber') }
  set inputFlightArrivalNumber(value: any) { this.inputFlightArrivalNumber.setValue(value) }
  get inputFlightDepartureName() { return this.form.get('flightDepartureName') }
  set inputFlightDepartureName(value: any) { this.inputFlightDepartureName.setValue(value) }
  get inputFlightDepartureNumber() { return this.form.get('flightDepartureNumber') }
  set inputFlightDepartureNumber(value: any) { this.inputFlightDepartureNumber.setValue(value) }
  get inputChildSeat() { return this.form.get('childSeat') }
  set inputChildSeat(value: any) { this.inputChildSeat.setValue(value) }
  get inputVoluminousEquipment() { return this.form.get('voluminousEquipment') }
  set inputVoluminousEquipment(value: any) { this.inputVoluminousEquipment.setValue(value) }
  get inputSpecialInstructions() { return this.form.get('specialInstructions') }
  set inputSpecialInstructions(value: any) { this.inputSpecialInstructions.setValue(value) }

  async ngOnInit() {
    this.isLoading = true;

    this.activatedRoute.params.subscribe(async routeParams => {
      this.getPaymentDetails(routeParams['uuid'])
      await this.getBookingDetails(routeParams['uuid'])

      if (this.booking.length === 0) {
        this.router.navigate(['/transportation/bookings'])
        this.toastService.error('El booking no existe.')
      } else {
        this.titleService.setTitle(`Detalles del booking: ${this.booking.foil}`)

        this.inputName = this.booking.contactInfo.name
        this.inputLastname = this.booking.contactInfo.lastname
        this.inputEmail = this.booking.contactInfo.email
        this.inputPhone = this.booking.contactInfo.phone
        this.inputCountry = this.booking.contactInfo.country
        this.inputCity = this.booking.contactInfo.city
        this.inputArrivalDate = new Date(this.booking.dateArrival)
        this.inputDepartureDate = new Date(this.booking.dateDeparture)
        this.inputFlightArrivalName = this.booking.flightInfo.arrival.airlineName
        this.inputFlightArrivalNumber = this.booking.flightInfo.arrival.flyNumber
        this.inputFlightDepartureName = this.booking.flightInfo.departure.airlineName
        this.inputFlightDepartureNumber = this.booking.flightInfo.departure.flyNumber
        this.inputChildSeat = this.booking.extra.childSeat
        this.inputVoluminousEquipment = this.booking.extra.voluminousEquipment
        this.inputSpecialInstructions = this.booking.extra.specialInstructions

        setTimeout(() => this.isLoading = false, 500);
      }
    });
  }

  getBookingDetails = async (uuid: any) => {
    await this.transportationService.getBookingDetails(uuid)
      .then((response: any) => this.booking = response)
  }

  getPaymentDetails = async (uuid: any) => {
    await this.paymentService.getPaymentDetails(uuid)
      .then((response: any) => this.payment = response)
  }

  copyToClipboard = async (str: any) => {
    try {
      await navigator.clipboard.writeText(str);
      this.toastService.success(`Copiado: ${str}.`)
    } catch (err) {
    }
  }

  public isVerifyPayment = false;
  verifyPayment = async () => {
    this.isVerifyPayment = true;

    await this.paymentService.syncGateway(this.payment.uuid)
      .then(() => {
        this.toastService.success('Se actualizó el pago.')
        this.getPaymentDetails(this.booking.uuid)
      })

    setTimeout(() => this.isVerifyPayment = false, 500);
  }

  save = async () => {
    this.isSaving = true;

    let body = {
      contactInfo: {
        name: this.inputName.value,
        lastname: this.inputLastname.value,
        email: this.inputEmail.value,
        phone: this.inputPhone.value,
        country: this.inputCountry.value,
        city: this.inputCity.value,
      },
      dates: {
        arrival: (new Date(this.inputArrivalDate.value).getTime() / 1000).toFixed(),
        departure: (new Date(this.inputDepartureDate.value).getTime() / 1000).toFixed()
      },
      flightInfo: {
        arrival: {
          airlineName: this.inputFlightArrivalName.value,
          flyNumber: this.inputFlightArrivalNumber.value
        },
        departure: {
          airlineName: this.inputFlightDepartureName.value,
          flyNumber: this.inputFlightDepartureNumber.value
        }
      },
      extras: {
        childSeat: this.inputChildSeat.value,
        voluminousEquipment: this.inputVoluminousEquipment.value,
        specialInstructions: this.inputSpecialInstructions.value,
      }
    }

    await this.transportationService.updateBooking(this.booking.uuid, body)
      .then(() => {
        this.toastService.success('Se actualizó el booking.')
        this.ngOnInit()
      })
      .catch(() => this.toastService.error('Error al actualizar el booking.'))

    this.isSaving = false;
  }
}
