import { Routes } from '@angular/router';
import { authGuard } from '@guards/auth.guard';
import { hasRolePermissionsGuard } from '@guards/has-role-permissions.guard';
import { TransportationZonesLocationsPage } from './zones-locations/zones-locations.component';
import { TransportationVehicleListPage } from './vehicle/list/list.component';
import { TransportationVehicleCreatePage } from './vehicle/create/create.component';
import { TransportationVehicleUpdatePage } from './vehicle/update/update.component';
import { TransportationRatesPage } from './rates/rates.component';
import { TransportationBookingListPage } from './booking/list/list.component';
import { TransportationBookingDetailsPage } from './booking/details/details.component';

let moduleName = 'transportation';

export const routes: Routes = [
  {
    path: `${moduleName}`,
    redirectTo: `${moduleName}/bookings`,
    pathMatch: 'full'
  },
  {
    path: `${moduleName}/zones-locations`,
    component: TransportationZonesLocationsPage,
    title: 'Transportación - Zonas y Ubicaciones',
    canActivate: [authGuard],
  },
  {
    path: `${moduleName}/rates`,
    component: TransportationRatesPage,
    title: 'Transportación - Tarifas',
    canActivate: [authGuard],
  },
  {
    path: `${moduleName}/vehicles`,
    children: [
      {
        path: ``,
        component: TransportationVehicleListPage,
        title: 'Transportación - Vehículos',
        canActivate: [authGuard],
      },
      {
        path: `create`,
        component: TransportationVehicleCreatePage,
        title: 'Transportación - Nuevo vehículo',
        canActivate: [authGuard],
      },
      {
        path: `details/:uuid`,
        component: TransportationVehicleUpdatePage,
        title: 'Transportación - Editar vehículo',
        canActivate: [authGuard],
      },
    ]
  },
  {
    path: `${moduleName}/bookings`,
    children: [
      {
        path: ``,
        component: TransportationBookingListPage,
        title: 'Transportación - Bookings',
        canActivate: [authGuard],
      },
      {
        path: `details/:uuid`,
        component: TransportationBookingDetailsPage,
        title: 'Transportación - Detalles de Booking',
        canActivate: [authGuard],
      },
    ]
  },
];
