import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';
import { TransportationService } from '@services/transportation.service';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { Table, TableModule } from 'primeng/table';
import { SkeletonModule } from 'primeng/skeleton';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { TagModule } from 'primeng/tag';

@Component({
  selector: 'app-transportation-booking-list',
  standalone: true,
  imports: [
    FormsModule,
    BackboneComponent,
    BreadcrumbComponent,
    TableModule,
    InputTextModule,
    ButtonModule,
    RouterModule,
    ShowForPermissionsDirective,
    SkeletonModule,
    CurrencyPipe,
    TagModule,
    CommonModule
  ],
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss'
})
export class TransportationBookingListPage {
  private transportationService = inject(TransportationService);

  public isLoading = false;
  public isDeleting = false;
  public bookingsList: any[] = [];
  public searchValue: string | undefined;

  async ngOnInit() {
    this.isLoading = true;

    await this.transportationService.getBookings().then((response: any) => this.bookingsList = response);

    setTimeout(() => this.isLoading = false, 500);
  }

  clear = (table: Table) => {
    table.clear();
    this.searchValue = ''
  }
}
