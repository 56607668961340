import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';
import { TransportationService } from '@services/transportation.service';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown'
import { ToastService } from '@utils/toast.service';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmDialogService } from '@utils/confirm-dialog.service';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-rates',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BackboneComponent,
    BreadcrumbComponent,
    TableModule,
    InputTextModule,
    ButtonModule,
    RouterModule,
    ShowForPermissionsDirective,
    CurrencyPipe,
    DialogModule,
    DropdownModule,
    InputNumberModule,
    SkeletonModule
  ],
  templateUrl: './rates.component.html',
  styleUrl: './rates.component.scss'
})
export class TransportationRatesPage {
  private transportationService = inject(TransportationService);
  private formBuilder = inject(FormBuilder);
  private toastService = inject(ToastService);
  private confirmDialogService = inject(ConfirmDialogService);

  public isLoading = false;
  public zonesList: any[] = [];
  public zonesRateList: any[] = [];
  public vehiclesList: any[] = [];
  public vehicleSelected: any = null;

  async ngOnInit() {
    this.isLoading = true;

    this.transportationService.getZones().then((response) => this.zonesList = response)
    this.transportationService.getZoneRate().then((response) => this.zonesRateList = response)
    this.transportationService.getVehicles().then((response) => this.vehiclesList = response)

    setTimeout(() => this.isLoading = false, 500);
  }

  getZoneName = (zoneId: number) => this.zonesList.find((zone) => zone.transportationZoneId === zoneId)?.label
  getVehicle = (vehicleId: number) => this.vehiclesList.find((vehicle) => vehicle.transportationVehicleId === vehicleId)

  public formCreateZoneRate = this.formBuilder.group({
    departure: [null, [Validators.required]],
    destination: [null, [Validators.required]],
    priceBaseOneway: [null, [Validators.required]],
    priceBaseRoundtrip: [null, [Validators.required]],
    priceCategory1Oneway: [null],
    priceCategory1Roundtrip: [null],
    priceCategory2Oneway: [null],
    priceCategory2Roundtrip: [null],
    priceCategory3Oneway: [null],
    priceCategory3Roundtrip: [null],
    priceCategory4Oneway: [null],
    priceCategory4Roundtrip: [null],
    priceCategory5Oneway: [null],
    priceCategory5Roundtrip: [null],
    vehicles: this.formBuilder.array([])
  });

  get formCreateZoneRateDeparture() { return this.formCreateZoneRate.get('departure') }
  set formCreateZoneRateDeparture(value: any) { this.formCreateZoneRateDeparture.setValue(value) }
  get formCreateZoneRateDestination() { return this.formCreateZoneRate.get('destination') }
  set formCreateZoneRateDestination(value: any) { this.formCreateZoneRateDestination.setValue(value) }
  get formCreateZoneRatePriceBaseOneway() { return this.formCreateZoneRate.get('priceBaseOneway') }
  set formCreateZoneRatePriceBaseOneway(value: any) { this.formCreateZoneRatePriceBaseOneway.setValue(value) }
  get formCreateZoneRatePriceBaseRoundtrip() { return this.formCreateZoneRate.get('priceBaseRoundtrip') }
  set formCreateZoneRatePriceBaseRoundtrip(value: any) { this.formCreateZoneRatePriceBaseRoundtrip.setValue(value) }
  get formCreateZoneRatePriceCategory1Oneway() { return this.formCreateZoneRate.get('priceCategory1Oneway') }
  set formCreateZoneRatePriceCategory1Oneway(value: any) { this.formCreateZoneRatePriceCategory1Oneway.setValue(value) }
  get formCreateZoneRatePriceCategory1Roundtrip() { return this.formCreateZoneRate.get('priceCategory1Roundtrip') }
  set formCreateZoneRatePriceCategory1Roundtrip(value: any) { this.formCreateZoneRatePriceCategory1Roundtrip.setValue(value) }
  get formCreateZoneRatePriceCategory2Oneway() { return this.formCreateZoneRate.get('priceCategory2Oneway') }
  set formCreateZoneRatePriceCategory2Oneway(value: any) { this.formCreateZoneRatePriceCategory2Oneway.setValue(value) }
  get formCreateZoneRatePriceCategory2Roundtrip() { return this.formCreateZoneRate.get('priceCategory2Roundtrip') }
  set formCreateZoneRatePriceCategory2Roundtrip(value: any) { this.formCreateZoneRatePriceCategory2Roundtrip.setValue(value) }
  get formCreateZoneRatePriceCategory3Oneway() { return this.formCreateZoneRate.get('priceCategory3Oneway') }
  set formCreateZoneRatePriceCategory3Oneway(value: any) { this.formCreateZoneRatePriceCategory3Oneway.setValue(value) }
  get formCreateZoneRatePriceCategory3Roundtrip() { return this.formCreateZoneRate.get('priceCategory3Roundtrip') }
  set formCreateZoneRatePriceCategory3Roundtrip(value: any) { this.formCreateZoneRatePriceCategory3Roundtrip.setValue(value) }
  get formCreateZoneRatePriceCategory4Oneway() { return this.formCreateZoneRate.get('priceCategory4Oneway') }
  set formCreateZoneRatePriceCategory4Oneway(value: any) { this.formCreateZoneRatePriceCategory4Oneway.setValue(value) }
  get formCreateZoneRatePriceCategory4Roundtrip() { return this.formCreateZoneRate.get('priceCategory4Roundtrip') }
  set formCreateZoneRatePriceCategory4Roundtrip(value: any) { this.formCreateZoneRatePriceCategory4Roundtrip.setValue(value) }
  get formCreateZoneRatePriceCategory5Oneway() { return this.formCreateZoneRate.get('priceCategory5Oneway') }
  set formCreateZoneRatePriceCategory5Oneway(value: any) { this.formCreateZoneRatePriceCategory5Oneway.setValue(value) }
  get formCreateZoneRatePriceCategory5Roundtrip() { return this.formCreateZoneRate.get('priceCategory5Roundtrip') }
  set formCreateZoneRatePriceCategory5Roundtrip(value: any) { this.formCreateZoneRatePriceCategory5Roundtrip.setValue(value) }

  get vehicles(): FormArray { return this.formCreateZoneRate.controls["vehicles"] as FormArray; }

  public dialogZoneRate = {
    visible: false,
    title: '',
    action: 'create',
    reference: null,
    idItem: null,
  }

  toggleDialogZoneRate = (status: 'show' | 'hide', title: string = '', action: 'create' | 'modify' = 'create', reference: any = null) => {
    if (status === 'show') {
      this.dialogZoneRate.visible = true;
      this.dialogZoneRate.title = title;
      this.dialogZoneRate.action = action;
      this.dialogZoneRate.reference = reference;

      if (action === 'modify') {
        this.dialogZoneRate.idItem = reference.transportationZoneRateId;
        this.formCreateZoneRateDeparture = reference.departureId
        this.formCreateZoneRateDestination = reference.destinationId
        this.formCreateZoneRatePriceBaseOneway = reference.basePrice.ONEWAY
        this.formCreateZoneRatePriceBaseRoundtrip = reference.basePrice.ROUNDTRIP

        if (reference.category1Price !== null) {
          this.formCreateZoneRatePriceCategory1Oneway = reference.category1Price.ONEWAY
          this.formCreateZoneRatePriceCategory1Roundtrip = reference.category1Price.ROUNDTRIP
        }

        if (reference.category2Price !== null) {
          this.formCreateZoneRatePriceCategory2Oneway = reference.category2Price.ONEWAY
          this.formCreateZoneRatePriceCategory2Roundtrip = reference.category2Price.ROUNDTRIP
        }

        if (reference.category3Price !== null) {
          this.formCreateZoneRatePriceCategory3Oneway = reference.category3Price.ONEWAY
          this.formCreateZoneRatePriceCategory3Roundtrip = reference.category3Price.ROUNDTRIP
        }

        if (reference.category4Price !== null) {
          this.formCreateZoneRatePriceCategory4Oneway = reference.category4Price.ONEWAY
          this.formCreateZoneRatePriceCategory4Roundtrip = reference.category4Price.ROUNDTRIP
        }

        if (reference.category5Price !== null) {
          this.formCreateZoneRatePriceCategory5Oneway = reference.category5Price.ONEWAY
          this.formCreateZoneRatePriceCategory5Roundtrip = reference.category5Price.ROUNDTRIP
        }

        if (reference.vehicles.length > 0) {
          for (const element of reference.vehicles) {
            this.addVehicleSelected(element.transportationVehicleId)
          }
        }
      }
    }

    if (status === 'hide') {
      this.dialogZoneRate.visible = false;
      this.dialogZoneRate.title = '';
      this.dialogZoneRate.action = 'create';
      this.dialogZoneRate.reference = null;
      this.dialogZoneRate.idItem = null;
      this.vehicles.clear()
      this.formCreateZoneRate.reset()
      this.onSaveZoneRate = false;
    }
  }

  public onSaveZoneRate = false;
  saveDialogZoneRate = () => {
    if (this.formCreateZoneRate.valid) {
      this.onSaveZoneRate = true;
      if (this.dialogZoneRate.action === 'create') {
        this.transportationService.createZoneRate(this.formCreateZoneRate.value)
          .then(() => {
            this.toastService.success('Se agregó una nueva zona.');
            this.toggleDialogZoneRate('hide');
            this.onSaveZoneRate = false;
            this.ngOnInit();
          })
      }

      if (this.dialogZoneRate.action === 'modify') {
        this.transportationService.updateZoneRate(this.dialogZoneRate.idItem, this.formCreateZoneRate.value)
          .then(() => {
            this.toastService.success(`Se modifico la tarifa.`);
            this.toggleDialogZoneRate('hide');
            this.onSaveZoneRate = false;
            this.ngOnInit();
          })
      }
    }
  }

  deleteDialogZoneRate = (event: Event, id: number) => {
    this.confirmDialogService.dialog('¿Está seguro de eliminar esta tarifa?', 'Continuar', 'Cancelar')
      .then(() => {
        this.transportationService.deleteZoneRate(id)
          .then(() => {
            this.toastService.success('La tarifa se eliminó.')
            this.ngOnInit();
          })
          .catch((error: any) => {
            this.toastService.error(error.error.data.message)
          })
      })
      .catch(() => { })
  }

  addVehicleSelected = (id: null | number = null) => this.vehicles.push(this.formBuilder.group({ vehicleId: (id === null) ? this.vehicleSelected : id }))

  deleteAssignedVehicle = (index: number) => this.vehicles.removeAt(index)
}
