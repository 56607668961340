<backbone>
  <section header-page="topbar" class="d-flex h-100 position-relative">
    <div class="w-50 d-flex justify-content-start align-items-center">
      <p class="m-0 fw-medium">Bookings</p>
    </div>

    <div class="w-50 d-flex justify-content-end align-items-center gap-3">
      <breadcrumb
        [links]="[{title: 'Dashboard'}, {title: 'Transportación', link: '/transportation'}, {title: 'Bookings'}]" />
    </div>
  </section>

  @if (this.isLoading === true) {
  <section wrapper>
    <div class="page-section card">
      <div class="card-body p-0">
        <p-table [value]="[1,2,3,4,5]" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th>Folio</th>
              <th>Cliente</th>
              <th>Llegada</th>
              <th>Tipo de servicio</th>
              <th>Salida - Destino</th>
              <th>Precio</th>
              <th>Estatus</th>
              <th>Terminal</th>
              <th>Referencia</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr>
              <td style="width: 100px;"><p-skeleton /></td>
              <td><p-skeleton /></td>
              <td><p-skeleton /></td>
              <td><p-skeleton /></td>
              <td><p-skeleton /></td>
              <td><p-skeleton /></td>
              <td><p-skeleton /></td>
              <td><p-skeleton /></td>
              <td><p-skeleton /></td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </section>
  }

  @else {
  <section wrapper>
    <div class="page-section card">
      <div class="card-body p-0">
        <p-table #dt1 [value]="this.bookingsList" dataKey="id" [rows]="25" [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[25, 50, 100]" [paginator]="true"
          [globalFilterFields]="['uuid','foil','email','name','departure.label','destination.label']">
          <ng-template pTemplate="caption">
            <div class="d-flex align-items-stretch justify-content-between gap-2">
              <span class="p-input-icon-left ml-auto w-50">
                <i class="pi pi-search"></i>
                <input pInputText type="text" [(ngModel)]="searchValue" class="w-100 h-50px"
                  (input)="dt1.filterGlobal(searchValue, 'contains')"
                  placeholder="Buscar por referencia, nombre, correo electrónico, salida, destino." />
              </span>
              <p-button label="Limpiar busqueda" [outlined]="true" icon="pi pi-filter-slash" (click)="clear(dt1)"
                styleClass="h-50px" />
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th>Folio</th>
              <th>Cliente</th>
              <th>Llegada</th>
              <th>Tipo de servicio</th>
              <th>Salida - Destino</th>
              <th class="text-center">Precio</th>
              <th>Estatus</th>
              <th class="text-center">Terminal</th>
              <th>Referencia</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td class="text-nowrap">{{item.foil}}</td>
              <td class="text-nowrap">
                <a routerLink="details/{{item.uuid}}">
                  <span class="d-block">{{item.name}}</span>
                  <small class="d-block">{{item.email}}</small>
                </a>
              </td>
              <td class="text-nowrap"><small>{{item.dateArrival | date: 'longDate'}}</small></td>
              <td class="text-nowrap"><small>{{item.typeService}}</small></td>
              <td class="text-nowrap">
                <small class="d-block">{{item.departure.label}}</small>
                <small class="d-block">{{item.destination.label}}</small>
              </td>
              <td class="text-nowrap text-end">{{item.price | currency: 'MXN'}}</td>
              <td class="text-nowrap">
                @if (item.status === 'CREATED' || item.status === 'PENDING') {
                <p-tag severity="warning" value="Pendiente" />
                }
                @if (item.status === 'CANCELLED') {
                <p-tag severity="danger" value="Cancelado" />
                }
                @if (item.status === 'EXPIRED') {
                <p-tag severity="danger" value="Expirado" />
                }
                @if (item.status === 'COMPLETED') {
                <p-tag severity="success" value="Pagado" />
                }
              </td>
              <td class="text-nowrap text-center">{{item.gateway}}</td>
              <td class="text-nowrap" style="width: 100px;"><small>{{item.uuid}}</small></td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td class="text-center opacity-50" colspan="8">No hay bookings</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </section>
  }
</backbone>