import { inject, Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})
export class TransportationService {
  private http = inject(HttpService)

  public getZones = async () => await this.http.get(`/v1/transportation/zone`, false, false, false)
    .then((response: any) => response.data.content)
    .catch((error: any) => error)

  public createZone = async (body: any) => await this.http.post(`/v1/transportation/zone`, body, false, false)
    .then((response: any) => response.data)

  public updateZone = async (value: any, body: any) => await this.http.put(`/v1/transportation/zone/${value}`, body, false, false)
    .then((response: any) => response.data)

  public deleteZone = async (id: number) => await this.http.delete(`/v1/transportation/zone/${id}`, false, false)
    .then((response: any) => response.data)

  public getZoneRate = async () => await this.http.get(`/v1/transportation/zonerate`, false, false, false)
    .then((response: any) => response.data.content)
    .catch((error: any) => error)

  public createZoneRate = async (body: any) => await this.http.post(`/v1/transportation/zonerate`, body, false, false)
    .then((response: any) => response.data)

  public updateZoneRate = async (value: any, body: any) => await this.http.put(`/v1/transportation/zonerate/${value}`, body, false, false)
    .then((response: any) => response.data)

  public deleteZoneRate = async (id: number) => await this.http.delete(`/v1/transportation/zonerate/${id}`, false, false)
    .then((response: any) => response.data)

  public getLocations = async () => await this.http.get(`/v1/transportation/locations`, false, false, false)
    .then((response: any) => response.data.content)
    .catch((error: any) => error)

  public createLocation = async (body: any) => await this.http.post(`/v1/transportation/locations`, body, false, false)
    .then((response: any) => response.data)

  public updateLocation = async (value: any, body: any) => await this.http.put(`/v1/transportation/locations/${value}`, body, false, false)
    .then((response: any) => response.data)

  public deleteLocation = async (id: number) => await this.http.delete(`/v1/transportation/locations/${id}`, false, false)
    .then((response: any) => response.data)

  public getVehicles = async () => await this.http.get(`/v1/transportation/vehicle`, false, false)
    .then((response: any) => response.data.content)
    .catch((error: any) => error)

  public getVehicleDetails = async (id: number) => await this.http.get(`/v1/transportation/vehicle/${id}?by=uuid`, false, false)
    .then((response: any) => response.data.content)
    .catch((error: any) => error)

  public createVehicle = async (body: any) => await this.http.post(`/v1/transportation/vehicle`, body, false, false)
    .then((response: any) => response.data)

  public updateVehicle = async (value: any, body: any) => await this.http.put(`/v1/transportation/vehicle/${value}`, body, false, false)
    .then((response: any) => response.data)

  public deleteVehicle = async (id: number) => await this.http.delete(`/v1/transportation/vehicle/${id}`, false, false)
    .then((response: any) => response.data)

  public getBookings = async () => await this.http.get(`/v1/transportation/booking`, false, false)
    .then((response: any) => response.data.content)
    .catch((error: any) => error)

  public getBooking = async () => await this.http.get(`/v1/transportation/booking`, false, false)
    .then((response: any) => response.data.content)
    .catch((error: any) => error)

  public getBookingDetails = async (id: number) => await this.http.get(`/v1/transportation/booking/${id}`, false, false)
    .then((response: any) => response.data.content)
    .catch((error: any) => error)

  public updateBooking = async (value: any, body: any) => await this.http.put(`/v1/transportation/booking/${value}`, body, false, false)
    .then((response: any) => response.data)
}
