import { inject, Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private http = inject(HttpService)

  public getPaymentDetails = async (uuid: string) => await this.http.get(`/v1/payment/${uuid}`, false, false)
    .then((response: any) => response.data.content)
    .catch((error: any) => error)

  public syncGateway = async (uuid: any) => await this.http.post(`/v1/payment/syncgateway/${uuid}`, {}, false, false)
    .then((response: any) => response.data)
}
