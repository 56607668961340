<backbone>
  <section header-page="topbar" class="d-flex h-100 position-relative">
    <div class="w-50 d-flex justify-content-start align-items-center">
      <p class="m-0 fw-medium">Booking #{{this.booking?.foil}}</p>
    </div>

    <div class="w-50 d-flex justify-content-end align-items-center gap-3">
      <breadcrumb
        [links]="[{title: 'Dashboard'}, {title: 'Transportación', link: '/transportation'}, {title: 'Bookings', link: '/transportation/bookings'}, {title: 'Detalles'}]" />

      @if (this.booking !== null) {
      <p-button size="small" icon="pi pi-save" label="Actualizar" [loading]="isSaving" (onClick)="save()"
        [disabled]="!form.valid"></p-button>
      }
    </div>
  </section>

  @if (this.isLoading === true) {
  <section wrapper>
    <div class="row">
      <div class="col-8">
        <div class="card page-section rounded-2 shadow-sm">
          <div class="card-header align-items-center d-flex rounded-top-2">
          </div>
          <div class="card-body">
            <div class="row">
              @for (item of [1,2,3,4,5,6,7,8]; track $index) {
              <div class="col-6 mb-3">
                <p-skeleton />
              </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card page-section rounded-2 shadow-sm">
          <div class="card-header align-items-center d-flex rounded-top-2">
          </div>
          <div class="card-body">
            <div class="row">
              @for (item of [1,2,3,4,5,6,7,8]; track $index) {
              <div class="col-6 mb-3">
                <p-skeleton />
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  }

  @else {
  <section wrapper>
    <form [formGroup]="form" [ngClass]="{'opacity-50 pointer-events-none user-select-none': this.isSaving === true}">
      <div class="row">
        <div class="col-8">
          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Información del cliente.</h6>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-6 mb-3">
                  <div class="d-flex flex-column gap-2">
                    <input formControlName="name" type="text" pInputText class="w-100 h-50px" />
                    <small class="ps-2">Nombre(s).</small>
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div class="d-flex flex-column gap-2">
                    <input formControlName="lastname" type="text" pInputText class="w-100 h-50px" />
                    <small class="ps-2">Apellidos.</small>
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div class="d-flex flex-column gap-2">
                    <input formControlName="email" type="text" pInputText class="w-100 h-50px" />
                    <small class="ps-2">Correo electrónico.</small>
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div class="d-flex flex-column gap-2">
                    <input formControlName="phone" type="text" pInputText class="w-100 h-50px" />
                    <small class="ps-2">Teléfono de contacto.</small>
                  </div>
                </div>
                <div class="col-6">
                  <div class="d-flex flex-column gap-2">
                    <input formControlName="country" type="text" pInputText class="w-100 h-50px" />
                    <small class="ps-2">País.</small>
                  </div>
                </div>
                <div class="col-6">
                  <div class="d-flex flex-column gap-2">
                    <input formControlName="city" type="text" pInputText class="w-100 h-50px" />
                    <small class="ps-2">Ciudad.</small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Información del servicio.</h6>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-4 mb-3">
                  <div class="d-flex flex-column gap-2">
                    <input [value]="this.booking?.typeService" type="text" pInputText class="w-100 h-50px"
                      [disabled]="true" />
                    <small class="ps-2">Tipo de servicio.</small>
                  </div>
                </div>
                <div class="col-2 mb-3">
                  <div class="d-flex flex-column gap-2">
                    <input [value]="this.booking?.pax?.adults" type="text" pInputText class="w-100 h-50px"
                      [disabled]="true" />
                    <small class="ps-2">Adultos.</small>
                  </div>
                </div>
                <div class="col-2 mb-3">
                  <div class="d-flex flex-column gap-2">
                    <input [value]="this.booking?.pax?.childrens" type="text" pInputText class="w-100 h-50px"
                      [disabled]="true" />
                    <small class="ps-2">Menores.</small>
                  </div>
                </div>
                <div class="col-4 mb-3">
                  <div class="d-flex flex-column gap-2">
                    <input value="{{this.booking?.price | currency: 'MXN'}}" type="text" pInputText class="w-100 h-50px"
                      [disabled]="true" />
                    <small class="ps-2">Precio.</small>
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div class="d-flex flex-column gap-2">
                    <input [value]="this.booking?.departure?.label" type="text" pInputText class="w-100 h-50px"
                      [disabled]="true" />
                    <small class="ps-2">Salida.</small>
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div class="d-flex flex-column gap-2">
                    <input [value]="this.booking?.destination?.label" type="text" pInputText class="w-100 h-50px"
                      [disabled]="true" />
                    <small class="ps-2">Destino.</small>
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div class="d-flex flex-column gap-2">
                    <p-calendar formControlName="arrivalDate" styleClass="w-100 h-50px" inputStyleClass="w-100 h-50px"
                      dateFormat="dd/mm/yy" [showTime]="true" [touchUI]="true" />
                    <small class="ps-2">Fecha de llegada.</small>
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div class="d-flex flex-column gap-2">
                    <p-calendar formControlName="departureDate" styleClass="w-100 h-50px" inputStyleClass="w-100 h-50px"
                      dateFormat="dd/mm/yy" [showTime]="true" [touchUI]="true" />
                    <small class="ps-2">Fecha de salida.</small>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex flex-column gap-2">
                    <input value="{{this.booking?.vehicle?.label}}" type="text" pInputText class="w-100 h-50px"
                      [disabled]="true" />
                    <small class="ps-2">Vehículo.</small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Información del vuelo.</h6>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-6 mb-3">
                  <div class="d-flex flex-column gap-2">
                    <input formControlName="flightArrivalName" type="text" pInputText class="w-100 h-50px" />
                    <small class="ps-2">Aerolinea de llegada.</small>
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div class="d-flex flex-column gap-2">
                    <input formControlName="flightArrivalNumber" type="text" pInputText class="w-100 h-50px" />
                    <small class="ps-2">Número de vuelo.</small>
                  </div>
                </div>
                <div class="col-6">
                  <div class="d-flex flex-column gap-2">
                    <input formControlName="flightDepartureName" type="text" pInputText class="w-100 h-50px" />
                    <small class="ps-2">Aerolinea de llegada.</small>
                  </div>
                </div>
                <div class="col-6">
                  <div class="d-flex flex-column gap-2">
                    <input formControlName="flightDepartureNumber" type="text" pInputText class="w-100 h-50px" />
                    <small class="ps-2">Número de vuelo.</small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Información extra.</h6>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-6 mb-3">
                  <div class="d-flex flex-column gap-2">
                    <p-dropdown formControlName="childSeat" [options]="yesOrNot" showClear="false"
                      styleClass="w-100 h-50px">
                      <ng-template pTemplate="selectedItem" let-selectedOption>
                        <div>{{ selectedOption.label }}</div>
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        <div>{{ item.label }}</div>
                      </ng-template>
                    </p-dropdown>
                    <small class="ps-2">Silla para bebé.</small>
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div class="d-flex flex-column gap-2">
                    <p-dropdown formControlName="voluminousEquipment" [options]="yesOrNot" showClear="false"
                      styleClass="w-100 h-50px">
                      <ng-template pTemplate="selectedItem" let-selectedOption>
                        <div>{{ selectedOption.label }}</div>
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        <div>{{ item.label }}</div>
                      </ng-template>
                    </p-dropdown>
                    <small class="ps-2">Equipaje voluminoso.</small>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex flex-column gap-2">
                    <textarea formControlName="specialInstructions" rows="5" cols="30" pInputTextarea></textarea>
                    <small class="ps-2">Instrucciones especiales.</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4">
          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Información del pago.</h6>

              @if (this.payment?.status !== 'COMPLETED') {
              <p-button size="small" icon="pi pi-credit-card" severity="success" label="Verficar pago"
                [loading]="this.isVerifyPayment" (onClick)="verifyPayment()"></p-button>
              }
            </div>
            <div class="card-body">
              <div class="d-flex flex-column gap-2">
                @if (this.payment?.gateway === 'CLIP') {
                <div class="d-flex align-items-center justify-content-between gap-5">
                  <p class="m-0">Terminal:</p>
                  <p class="m-0 fw-medium">{{this.payment?.gateway}}</p>
                </div>
                <div class="d-flex align-items-center justify-content-between gap-5">
                  <p class="m-0">Referencia:</p>
                  <p class="m-0 fw-medium d-flex align-items-center gap-2">
                    <p-button size="small" icon="pi pi-copy" [rounded]="true" [text]="true" severity="secondary"
                      label="copiar" (onClick)="copyToClipboard(this.payment?.uuid)" />
                    <small>{{this.payment?.uuid}}</small>
                  </p>
                </div>
                <div class="d-flex align-items-center justify-content-between gap-5">
                  <p class="m-0">Estatus:</p>
                  <p class="m-0 fw-medium">
                    @if (this.payment?.status === 'CREATED' || this.payment?.status === 'PENDING') {
                    <span class="text-bg-warning py-1 px-2 rounded-2">Pendiente</span>
                    }
                    @if (this.payment?.status === 'CANCELLED') {
                    <span class="text-bg-danger py-1 px-2 rounded-2 text-light">Cancelado</span>
                    }
                    @if (this.payment?.status === 'EXPIRED') {
                    <span class="text-bg-danger py-1 px-2 rounded-2 text-light">Expirado</span>
                    }
                    @if (this.payment?.status === 'COMPLETED') {
                    <span class="text-bg-success py-1 px-2 rounded-2 text-light">Pagado</span>
                    }
                  </p>
                </div>
                <div class="d-flex align-items-center justify-content-between gap-5">
                  <p class="m-0">Monto:</p>
                  <p class="m-0 fw-medium">{{this.payment?.gatewayResponse?.amount | currency: "MXN"}} {{
                    this.payment?.gatewayResponse?.currency }}</p>
                </div>
                <div class="d-flex align-items-center justify-content-between gap-5">
                  <p class="m-0">Descripción:</p>
                  <p class="m-0 fw-medium"><small>{{ this.payment?.gatewayResponse?.purchase_description }}</small></p>
                </div>
                <div class="d-flex align-items-center justify-content-between gap-5">
                  <p class="m-0">Fecha de creación:</p>
                  <p class="m-0 fw-medium">{{ this.payment?.gatewayResponse?.created_at | date: 'MMMM d, y, h:mm:ss a' |
                    uppercase }}
                  </p>
                </div>
                <div class="d-flex align-items-center justify-content-between gap-5">
                  <p class="m-0">Última actualización:</p>
                  <p class="m-0 fw-medium">{{ this.payment?.gatewayResponse?.modified_at| date: 'MMMM d, y, h:mm:ss a' |
                    uppercase }}
                  </p>
                </div>
                <div class="d-flex align-items-center justify-content-between gap-5">
                  <p class="m-0">Fecha de expiración:</p>
                  <p class="m-0 fw-medium">{{ this.payment?.gatewayResponse?.expires_at| date: 'MMMM d, y, h:mm:ss a' |
                    uppercase }}
                  </p>
                </div>
                <div class="d-flex align-items-center justify-content-between gap-5">
                  <p class="m-0">Enlace de voucher</p>
                  <p class="m-0 fw-medium d-flex align-items-center gap-2">
                    <p-button size="small" icon="pi pi-copy" [rounded]="true" [text]="true" severity="secondary"
                      label="copiar"
                      (onClick)="copyToClipboard(this.payment?.gatewayResponse?.redirection_url?.success)" />
                    <a [href]="this.payment?.gatewayResponse?.redirection_url?.success" target="_blank"
                      class="btn btn-light">
                      <i class="bi bi-box-arrow-up-right"></i>
                    </a>
                  </p>
                </div>
                <div class="d-flex align-items-center justify-content-between gap-5">
                  <p class="m-0">Enlace de pago</p>
                  <p class="m-0 fw-medium d-flex align-items-center gap-2">
                    <p-button size="small" icon="pi pi-copy" [rounded]="true" [text]="true" severity="secondary"
                      label="copiar" (onClick)="copyToClipboard(this.payment?.gatewayResponse?.payment_request_url)" />
                    <a [href]="this.payment?.gatewayResponse?.payment_request_url" target="_blank"
                      class="btn btn-light">
                      <i class="bi bi-box-arrow-up-right"></i>
                    </a>
                  </p>
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
  }
</backbone>