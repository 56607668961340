import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { PreviewUploadImageComponent } from '@components/preview-upload-image/preview-upload-image.component';
import { TransportationService } from '@services/transportation.service';
import { ToastService } from '@utils/toast.service';
import { requiredFileSize, requiredFileType } from '@utils/validators';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-vehicle-create',
  standalone: true,
  imports: [
    BackboneComponent,
    BreadcrumbComponent,
    ReactiveFormsModule,
    CommonModule,
    InputTextModule,
    DropdownModule,
    InputNumberModule,
    PreviewUploadImageComponent,
    ButtonModule
  ],
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss'
})
export class TransportationVehicleCreatePage {
  private formBuilder = inject(FormBuilder);
  private toastService = inject(ToastService);
  private router = inject(Router);
  private transportationService = inject(TransportationService);

  public isLoading = false;
  public isSaving = false;

  public maxSizesUpload = 2000000;
  public years: any = []
  public categoryPrices: any = [
    { label: 'Sin categoría', value: null, description: 'Precio base.' },
    { label: 'Categoría 1', value: 1, description: 'Taxi.' },
    { label: 'Categoría 2', value: 2, description: 'Privado económico.' },
    { label: 'Categoría 3', value: 3, description: 'Privado en camioneta.' },
    { label: 'Categoría 4', value: 4, description: 'Privado de lujo.' },
    { label: 'Categoría 5', value: 5, description: 'Privado para grupo.' }
  ]

  public form = this.formBuilder.group({
    title: [null, [Validators.required]],
    brand: [null, [Validators.required]],
    model: [null, [Validators.required]],
    year: [null, [Validators.required]],
    passengers: [null, [Validators.required]],
    category: [null],
    hero: [null, [Validators.required, requiredFileType(['png', 'jpg', 'jpeg']), requiredFileSize(this.maxSizesUpload)]]
  });

  get inputTitle() { return this.form.get('title') }
  set inputTitle(value: any) { this.inputTitle.setValue(value) }
  get inputBrand() { return this.form.get('brand') }
  set inputBrand(value: any) { this.inputBrand.setValue(value) }
  get inputModel() { return this.form.get('model') }
  set inputModel(value: any) { this.inputModel.setValue(value) }
  get inputYear() { return this.form.get('year') }
  set inputYear(value: any) { this.inputYear.setValue(value) }
  get inputPassengers() { return this.form.get('passengers') }
  set inputPassengers(value: any) { this.inputPassengers.setValue(value) }
  get inputCategory() { return this.form.get('category') }
  set inputCategory(value: any) { this.inputCategory.setValue(value) }
  get inputHero() { return this.form.get('hero') }
  set inputHero(value: any) { this.inputHero.setValue(value) }

  async ngOnInit() {
    let date = new Date();

    for (let i = date.getFullYear() + 1; i >= date.getFullYear() - 10; i--) {
      this.years.push({ label: i, value: i });
    }
  }

  onHeroChanged = (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.inputHero = event.target.files[0];
    }
  }

  save = async () => {
    this.isSaving = true;

    const myFormData = new FormData();

    myFormData.append('title', this.inputTitle.value)
    myFormData.append('brand', this.inputBrand.value)
    myFormData.append('model', this.inputModel.value)
    myFormData.append('year', this.inputYear.value)
    myFormData.append('passengers', this.inputPassengers.value)
    myFormData.append('category', this.inputCategory.value)
    myFormData.append('hero', this.inputHero.value)

    await this.transportationService.createVehicle(myFormData)
      .then(() => {
        this.toastService.success('Se agregó el vehículo.')
        this.router.navigate(['/transportation/vehicles'])
      })
      .catch(() => this.toastService.error('Error al crear el vehículo.'))

    this.isSaving = false;
  }
}
