<aside id="sidebar">
  <header class="border-bottom">
    <div class="logo">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-columns-gap"
        viewBox="0 0 16 16">
        <path
          d="M6 1v3H1V1zM1 0a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zm14 12v3h-5v-3zm-5-1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM6 8v7H1V8zM1 7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1zm14-6v7h-5V1zm-5-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1z" />
      </svg>
    </div>
    <h5 class="title w-100 m-0">
      <span class="d-block fs-6">Panel de</span>
      <span class="d-block fs-6">administración</span>
    </h5>
  </header>

  <main>
    <nav class="mb-4">
      <ul class="nav-menu list-unstyled m-0">
        <li class="nav-item">
          <a routerLink="/home" routerLinkActive="active" class="nav-link">
            <i class="nav-icon bi bi-house"></i>
            Inicio
          </a>
        </li>
        <li *showForPermissions="['read_user']" class="nav-item">
          <a routerLink="/user" routerLinkActive="active" class="nav-link">
            <i class="nav-icon bi bi-people"></i>
            Usuarios
          </a>
        </li>
        <!-- <li *showForPermissions="['read_dynamic_pages']" class="nav-item">
          <a routerLink="/pages" routerLinkActive="active" class="nav-link">
            <i class="nav-icon bi bi-files"></i>
            Páginas
          </a>
        </li> -->
      </ul>
    </nav>

    <!-- <nav class="mb-4" *showForPermissions="['read_blog']">
      <p class="nav-title mb-2">Blog</p>

      <ul class="nav-menu list-unstyled m-0">
        <li class="nav-item">
          <a routerLink="/blog" routerLinkActive="active" class="nav-link">
            <i class="nav-icon bi bi-journal-bookmark-fill"></i>
            Lista de entradas
          </a>
        </li>
      </ul>
    </nav> -->

    <nav class="mb-4">
      <p class="nav-title mb-2">Transportación</p>

      <ul class="nav-menu list-unstyled m-0">
        <li class="nav-item">
          <a routerLink="/transportation/bookings" routerLinkActive="active" class="nav-link">
            <i class="nav-icon bi bi-calendar4-week"></i>
            Bookings
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/transportation/vehicles" routerLinkActive="active" class="nav-link">
            <i class="nav-icon bi bi-car-front"></i>
            Vehículos
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/transportation/rates" routerLinkActive="active" class="nav-link">
            <i class="nav-icon bi bi-card-checklist"></i>
            Tarifas
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/transportation/zones-locations" routerLinkActive="active" class="nav-link">
            <i class="nav-icon bi bi-flag"></i>
            Zonas y Ubicaciones
          </a>
        </li>
      </ul>
    </nav>
  </main>

  <footer class="border-top">
    <a class="nav-link w-100" (click)="logout()">
      <i class="nav-icon bi bi-box-arrow-right"></i> Bloquear sesión
    </a>
  </footer>
</aside>