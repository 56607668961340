import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';
import { TransportationService } from '@services/transportation.service';
import { ConfirmDialogService } from '@utils/confirm-dialog.service';
import { ToastService } from '@utils/toast.service';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-vehicle-list',
  standalone: true,
  imports: [
    FormsModule,
    BackboneComponent,
    BreadcrumbComponent,
    TableModule,
    InputTextModule,
    ButtonModule,
    RouterModule,
    ShowForPermissionsDirective,
    SkeletonModule
  ],
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss'
})
export class TransportationVehicleListPage {
  private transportationService = inject(TransportationService);
  private confirmDialogService = inject(ConfirmDialogService);
  private toastService = inject(ToastService);

  public vehiclesList: any[] = [];
  public isLoading = false;
  public isDeleting = false;

  async ngOnInit() {
    this.isLoading = true;

    await this.transportationService.getVehicles().then((response: any) => this.vehiclesList = response);

    setTimeout(() => this.isLoading = false, 500);
  }

  delete = (id: number) => {
    this.isDeleting = true;

    this.confirmDialogService.dialog('¿Está seguro de eliminar el vehículo?', 'Continuar', 'Cancelar')
      .then(() => {
        this.transportationService.deleteVehicle(id)
          .then(() => {
            this.toastService.success('El vehículo se eliminó.')
            this.ngOnInit();
          })
          .catch((error: any) => {
            this.toastService.error(error.error.data.message)
            this.isDeleting = false;
          })
      })
      .catch(() => this.isDeleting = false)
  }
}
