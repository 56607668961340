import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { PreviewUploadImageComponent } from '@components/preview-upload-image/preview-upload-image.component';
import { TransportationService } from '@services/transportation.service';
import { ConfirmDialogService } from '@utils/confirm-dialog.service';
import { ToastService } from '@utils/toast.service';
import { requiredFileSize, requiredFileType } from '@utils/validators';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-vehicle-update',
  standalone: true,
  imports: [
    BackboneComponent,
    BreadcrumbComponent,
    ReactiveFormsModule,
    CommonModule,
    InputTextModule,
    DropdownModule,
    InputNumberModule,
    PreviewUploadImageComponent,
    ButtonModule
  ],
  templateUrl: './update.component.html',
  styleUrl: './update.component.scss'
})
export class TransportationVehicleUpdatePage {
  private activatedRoute = inject(ActivatedRoute);
  private formBuilder = inject(FormBuilder);
  private toastService = inject(ToastService);
  private router = inject(Router);
  private transportationService = inject(TransportationService);
  private titleService = inject(Title);
  private confirmDialogService = inject(ConfirmDialogService);

  public isLoading = false;
  public isSaving = false;
  public isDeleting = false;

  public data: any
  public maxSizesUpload = 2000000;
  public years: any = []
  public categoryPrices: any = [
    { label: 'Sin categoría', value: null, description: 'Precio base.' },
    { label: 'Categoría 1', value: 1, description: 'Taxi.' },
    { label: 'Categoría 2', value: 2, description: 'Privado económico.' },
    { label: 'Categoría 3', value: 3, description: 'Privado en camioneta.' },
    { label: 'Categoría 4', value: 4, description: 'Privado de lujo.' },
    { label: 'Categoría 5', value: 5, description: 'Privado para grupo.' }
  ]

  public form = this.formBuilder.group({
    title: [null, [Validators.required]],
    brand: [null, [Validators.required]],
    model: [null, [Validators.required]],
    year: [null, [Validators.required]],
    passengers: [null, [Validators.required]],
    category: [null],
    hero: [null, [requiredFileType(['png', 'jpg', 'jpeg']), requiredFileSize(this.maxSizesUpload)]]
  });

  get inputTitle() { return this.form.get('title') }
  set inputTitle(value: any) { this.inputTitle.setValue(value) }
  get inputBrand() { return this.form.get('brand') }
  set inputBrand(value: any) { this.inputBrand.setValue(value) }
  get inputModel() { return this.form.get('model') }
  set inputModel(value: any) { this.inputModel.setValue(value) }
  get inputYear() { return this.form.get('year') }
  set inputYear(value: any) { this.inputYear.setValue(value) }
  get inputPassengers() { return this.form.get('passengers') }
  set inputPassengers(value: any) { this.inputPassengers.setValue(value) }
  get inputCategory() { return this.form.get('category') }
  set inputCategory(value: any) { this.inputCategory.setValue(value) }
  get inputHero() { return this.form.get('hero') }
  set inputHero(value: any) { this.inputHero.setValue(value) }

  async ngOnInit() {
    this.isLoading = true;

    let date = new Date();

    for (let i = date.getFullYear() + 1; i >= date.getFullYear() - 10; i--) {
      this.years.push({ label: i, value: i });
    }

    this.activatedRoute.params.subscribe(async routeParams => {
      await this.transportationService.getVehicleDetails(routeParams['uuid'])
        .then((response: any) => this.data = response)

      if (this.data.length === 0) {
        this.router.navigate(['/transportation/vehicles'])
        this.toastService.error('El vehículo no existe.')
      } else {
        this.titleService.setTitle(`Viendo el vehículo: ${this.data.label}`)

        this.inputTitle = this.data.label
        this.inputBrand = this.data.brand
        this.inputModel = this.data.model
        this.inputYear = this.data.year
        this.inputPassengers = this.data.passengers
        this.inputCategory = this.data.categoryPrice

        this.isLoading = false;
      }
    });
  }

  onHeroChanged = (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.inputHero = event.target.files[0];
    }
  }

  save = async () => {
    this.isSaving = true;

    const myFormData = new FormData();

    myFormData.append('title', this.inputTitle.value)
    myFormData.append('brand', this.inputBrand.value)
    myFormData.append('model', this.inputModel.value)
    myFormData.append('year', this.inputYear.value)
    myFormData.append('passengers', this.inputPassengers.value)
    myFormData.append('category', this.inputCategory.value)
    myFormData.append('hero', this.inputHero.value)

    await this.transportationService.updateVehicle(this.data.transportationVehicleId, myFormData)
      .then(() => {
        this.toastService.success('Se actualizó el vehículo.')
      })
      .catch(() => this.toastService.error('Error al actualizar el vehículo.'))

    this.isSaving = false;
  }

  delete = (event: Event) => {
    this.isDeleting = true;

    this.confirmDialogService.dialog('¿Está seguro de eliminar el vehículo?', 'Continuar', 'Cancelar')
      .then(() => {
        this.isLoading = true;

        this.transportationService.deleteVehicle(this.data.transportationVehicleId)
          .then(() => {
            this.toastService.success('El vehículo se eliminó.')
            this.router.navigate(['/transportation/vehicles'])
          })
          .catch((error: any) => {
            this.toastService.error(error.error.data.message)
            this.isDeleting = false;
            this.isLoading = false;
          })
      })
      .catch(() => this.isDeleting = false)
  }
}
