<backbone>
  <section header-page="topbar" class="d-flex h-100 position-relative">
    <div class="w-50 d-flex justify-content-start align-items-center">
      <p class="m-0 fw-medium">Configuracion de las zonas y ubicaciones</p>
    </div>

    <div class="w-50 d-flex justify-content-end align-items-center gap-3">
      <breadcrumb
        [links]="[{title: 'Dashboard'}, {title: 'Transportación', link: '/transportation'}, {title: 'Zonas y ubicaciones'}]" />
    </div>
  </section>

  <section wrapper>
    <div class="page-section card">
      <div class="card-header py-2 px-3">
        <div class="d-flex justify-content-between align-items-center">
          <p class="m-0 fw-medium">Zonas de transportación</p>
          <p-button (onClick)="toggleDialogZone('show', 'Crear una nueva zona')" label="Nuevo" size="small" />
        </div>
      </div>
      <div class="card-body p-0">
        @if (this.isLoadingZones === true) {
        <p-table [value]="[1,2,3,4,5]" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th>Nombre</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr>
              <td><p-skeleton /></td>
            </tr>
          </ng-template>
        </p-table>
        }

        @else {
        <p-table #dt1 [value]="zonesList" dataKey="transportationZoneId" [rows]="25"
          [rowsPerPageOptions]="[10, 25, 50, 100]" [paginator]="true">
          <ng-template pTemplate="header">
            <tr>
              <th colspan="2">Nombre</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td class="text-nowrap">{{item.label}}</td>
              <td class="text-nowrap">
                <div class="d-flex justify-content-end">
                  <p-button icon="pi pi-pencil" severity="warning" [text]="true"
                    (onClick)="toggleDialogZone('show', 'Actualizar zona: ' + item.label, 'modify', item)" />
                  <p-button icon="pi pi-trash" severity="danger" [text]="true"
                    (onClick)="deleteDialogZone($event, item.transportationZoneId)" />
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td class="text-center opacity-50" colspan="4">No zonas de transportación</td>
            </tr>
          </ng-template>
        </p-table>
        }
      </div>
    </div>

    <div class="page-section card">
      <div class="card-header py-2 px-3">
        <div class="d-flex justify-content-between align-items-center">
          <p class="m-0 fw-medium">Ubicaciones</p>
          <p-button (onClick)="toggleDialogLocation('show', 'Crear una nueva ubicación en la zona')" label="Nuevo"
            size="small" />
        </div>
      </div>
      <div class="card-body p-0">
        @if (this.isLoadingLocations === true) {
        <p-table [value]="[1,2,3,4,5]" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th>Zona</th>
              <th>Ubicaciones</th>
              <th>Tipo</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr>
              <td><p-skeleton /></td>
              <td><p-skeleton /></td>
              <td><p-skeleton /></td>
            </tr>
          </ng-template>
        </p-table>
        }

        @else {
        <p-table #dt2 [value]="locationsList" dataKey="transportationLocationId" [rows]="25"
          [rowsPerPageOptions]="[10, 25, 50, 100]" [paginator]="true">
          <ng-template pTemplate="header">
            <tr>
              <th>Zona</th>
              <th>Ubicaciones</th>
              <th colspan="2">Tipo</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td class="text-nowrap">{{item.transportationZone}}</td>
              <td class="text-nowrap">{{item.label}}</td>
              <td class="text-nowrap">{{item.type}}</td>
              <td class="text-nowrap">
                <div class="d-flex justify-content-end">
                  <p-button icon="pi pi-pencil" severity="warning" [text]="true"
                    (onClick)="toggleDialogLocation('show', 'Actualizar ubicación: ' + item.label, 'modify', item)" />
                  <p-button icon="pi pi-trash" severity="danger" [text]="true"
                    (onClick)="deleteDialogLocation($event, item.transportationLocationId)" />
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td class="text-center opacity-50" colspan="4">No hay ubicaciones</td>
            </tr>
          </ng-template>
        </p-table>
        }
      </div>
    </div>
  </section>
</backbone>

<p-dialog [formGroup]="formCreateZone" [header]="dialogZone.title" [modal]="true" [(visible)]="dialogZone.visible"
  [position]="'top'" [draggable]="false" [style]="{ width: '50rem' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" (onHide)="toggleDialogZone('hide')">
  <div class="py-4">
    <div class="d-flex flex-column gap-2">
      <input formControlName="name" placeholder="Nombre de la zona" type="text" pInputText class="w-100 h-50px" />
      <small class="ps-2">Escribe un nombre para la zona. <span class="text-danger">Requerido *</span></small>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button label="Cancelar" [text]="true" severity="primary" (onClick)="toggleDialogZone('hide')" />
    <p-button label="Guardar" severity="primary" [loading]="onSaveZone" [disabled]="!formCreateZone.valid"
      (onClick)="saveDialogZone()" />
  </ng-template>
</p-dialog>

<p-dialog [formGroup]="formCreateLocation" [header]="dialogLocation.title" [modal]="true"
  [(visible)]="dialogLocation.visible" [position]="'top'" [draggable]="false" [style]="{ width: '50rem' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" (onHide)="toggleDialogLocation('hide')">
  <div class="py-4">
    <div class="d-flex flex-column gap-2 mb-3">
      <p-dropdown formControlName="zone" [options]="zonesList" appendTo="body" optionLabel="label"
        optionValue="transportationZoneId" showClear="false" [filter]="true" filterBy="label"
        placeholder="Selecciona una zona" emptyMessage="No hay resultados"
        emptyFilterMessage="No se encontraron resultados" styleClass="w-100 h-50px">
        <ng-template pTemplate="selectedItem" let-selectedOption>
          <div>{{ selectedOption.label }}</div>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <div>{{ item.label }}</div>
        </ng-template>
      </p-dropdown>
      <small class="ps-2">Selecciona la zona en la que pertenece esta ubicación. <span class="text-danger">Requerido
          *</span></small>
    </div>

    <div class="d-flex flex-column gap-2 mb-3">
      <input formControlName="name" placeholder="Nombre de la ubicación" type="text" pInputText class="w-100 h-50px" />
      <small class="ps-2">Escribe un nombre para la ubicación. <span class="text-danger">Requerido *</span></small>
    </div>

    <div class="d-flex flex-column gap-2">
      <p-dropdown formControlName="type" [options]="typeLocations" appendTo="body" showClear="false"
        placeholder="Selecciona un tipo" styleClass="w-100 h-50px">
        <ng-template pTemplate="selectedItem" let-selectedOption>
          <div>{{ selectedOption.label }}</div>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <div>{{ item.label }}</div>
        </ng-template>
      </p-dropdown>
      <small class="ps-2">Selecciona un tipo de ubicación. <span class="text-danger">Requerido *</span></small>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <p-button label="Cancelar" [text]="true" severity="primary" (onClick)="toggleDialogLocation('hide')" />
    <p-button label="Guardar" severity="primary" [disabled]="!formCreateLocation.valid" [loading]="onSaveLocation"
      (onClick)="saveDialogLocation()" />
  </ng-template>
</p-dialog>