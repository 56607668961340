<backbone>
  <section header-page="topbar" class="d-flex h-100 position-relative">
    <div class="w-50 d-flex justify-content-start align-items-center">
      <p class="m-0 fw-medium">Nuevo vehículo a la flotilla</p>
    </div>

    <div class="w-50 d-flex justify-content-end align-items-center gap-3">
      <breadcrumb
        [links]="[{title: 'Dashboard'}, {title: 'Transportación', link: '/transportation'}, {title: 'Vehículos', link: '/transportation/vehicles'}, {title: 'Nuevo'}]" />

      <p-button size="small" icon="pi pi-save" label="Crear" [loading]="isSaving" (onClick)="save()"
        [disabled]="!form.valid"></p-button>
    </div>
  </section>

  <section wrapper>
    <form [formGroup]="form" [ngClass]="{'opacity-50 pointer-events-none user-select-none': this.isSaving === true}">
      <div class="row">
        <div class="col-8">
          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Información del vehículo.</h6>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12 mb-3">
                  <div class="d-flex flex-column gap-2">
                    <input formControlName="title" placeholder="Nombre del vehículo" type="text" pInputText
                      class="w-100 h-50px" />
                    <small class="ps-2">Escribe un nombre para identificar el vehículo. <span
                        class="text-danger">Requerido *</span></small>
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div class="d-flex flex-column gap-2">
                    <input formControlName="brand" placeholder="Marca" type="text" pInputText class="w-100 h-50px" />
                    <small class="ps-2">Escribe la marca del vehículo. <span class="text-danger">Requerido
                        *</span></small>
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div class="d-flex flex-column gap-2">
                    <input formControlName="model" placeholder="Modelo" type="text" pInputText class="w-100 h-50px" />
                    <small class="ps-2">Escribe el modelo del vehículo. <span class="text-danger">Requerido
                        *</span></small>
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div class="d-flex flex-column gap-2">
                    <p-dropdown formControlName="year" [options]="years" showClear="false" placeholder="Año"
                      styleClass="w-100 h-50px">
                      <ng-template pTemplate="selectedItem" let-selectedOption>
                        <div>{{ selectedOption.label }}</div>
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        <div>{{ item.label }}</div>
                      </ng-template>
                    </p-dropdown>
                    <small class="ps-2">Escribe el año del vehículo. <span class="text-danger">Requerido
                        *</span></small>
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div class="d-flex flex-column gap-2">
                    <p-inputNumber formControlName="passengers" [step]="1" [min]="0" [maxFractionDigits]="0"
                      [showButtons]="true" buttonLayout="horizontal" decrementButtonClass="p-button-primary me-1"
                      decrementButtonIcon="pi pi-minus" incrementButtonClass="p-button-primary ms-1"
                      incrementButtonIcon="pi pi-plus" styleClass="w-100 h-50px"
                      inputStyleClass="text-center"></p-inputNumber>
                    <small class="ps-2">Escribe el número de pasajeros permitidos en el vehículo. <span
                        class="text-danger">Requerido *</span></small>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex flex-column gap-2">
                    <p-dropdown formControlName="category" [options]="categoryPrices" showClear="false"
                      placeholder="Categoría de precios" styleClass="w-100 h-50px">
                      <ng-template pTemplate="selectedItem" let-selectedOption>
                        <div class="d-flex flex-row justify-content-start align-items-center gap-1">
                          <span>{{ selectedOption.label }}</span>
                          <span class="opacity-50">-</span>
                          <span class="opacity-50">{{ selectedOption.description }}</span>
                        </div>
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        <div>{{ item.label }}</div>
                        <div><small>{{ item.description }}</small></div>
                      </ng-template>
                    </p-dropdown>
                    <small class="ps-2">Selecciona la categoría de precios en la que será accesible este vehículo. <span
                        class="text-danger">Requerido *</span></small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4">
          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Imágen principal.</h6>
            </div>
            <div class="card-body">
              <div class="d-flex flex-column gap-2">
                <preview-upload-image [image]="inputHero.value" imageSrc="./assets/images/media-placeholder.png"
                  [ngClass]="{'border border-danger border-4 rounded-1': inputHero.value?.size > this.maxSizesUpload}">
                  <input #input (change)="onHeroChanged($event)" class="form-control h-50px" type="file"
                    style="line-height: 2.6;" accept="image/*">
                </preview-upload-image>
                @if (inputHero.value?.size > this.maxSizesUpload) {
                <small class="px-2 text-danger">El tamaño de la imagen no debe ser mayor a 2mb.</small>
                }
                <small class="ps-2 d-block">Formatos JPG, JPEG y PNG. Tamaño máximo 2mb. <span
                    class="text-danger">Requerido *</span></small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
</backbone>