import { CurrencyPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';
import { TransportationService } from '@services/transportation.service';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { Table, TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown'
import { ToastService } from '@utils/toast.service';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmDialogService } from '@utils/confirm-dialog.service';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-transportation-settings',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    BackboneComponent,
    BreadcrumbComponent,
    TableModule,
    InputTextModule,
    ButtonModule,
    RouterModule,
    ShowForPermissionsDirective,
    CurrencyPipe,
    DialogModule,
    DropdownModule,
    InputNumberModule,
    SkeletonModule
  ],
  templateUrl: './zones-locations.component.html',
  styleUrl: './zones-locations.component.scss'
})
export class TransportationZonesLocationsPage {
  private transportationService = inject(TransportationService);
  private formBuilder = inject(FormBuilder);
  private toastService = inject(ToastService);
  private confirmDialogService = inject(ConfirmDialogService);

  public zonesList: any[] = [];
  public locationsList: any[] = [];

  async ngOnInit() {
    this.getZones()
    this.getLocations()
  }

  clear = (table: Table) => table.clear();
  getZoneName = (zoneId: number) => this.zonesList.find((zone) => zone.transportationZoneId === zoneId)?.label


  // ------------------- Zone -------------------
  public isLoadingZones = false;

  public getZones = async () => {
    this.isLoadingZones = true;

    await this.transportationService.getZones().then((response) => this.zonesList = response)

    setTimeout(() => this.isLoadingZones = false, 500);
  }

  public formCreateZone = this.formBuilder.group({
    name: [null, [Validators.required]],
  });

  get formCreateZoneName() { return this.formCreateZone.get('name') }
  set formCreateZoneName(value: any) { this.formCreateZoneName.setValue(value) }

  public dialogZone = {
    visible: false,
    title: '',
    action: 'create',
    reference: null,
    idItem: null,
  }

  toggleDialogZone = (status: 'show' | 'hide', title: string = '', action: 'create' | 'modify' = 'create', reference: any = null) => {
    if (status === 'show') {
      this.dialogZone.visible = true;
      this.dialogZone.title = title;
      this.dialogZone.action = action;
      this.dialogZone.reference = reference;

      if (action === 'modify') {
        this.dialogZone.idItem = reference.transportationZoneId;
        this.formCreateZoneName = reference.label
      }
    }

    if (status === 'hide') {
      this.dialogZone.visible = false;
      this.dialogZone.title = '';
      this.dialogZone.action = 'create';
      this.dialogZone.reference = null;
      this.dialogZone.idItem = null;
      this.formCreateZone.reset()
      this.onSaveZone = false;
    }
  }

  public onSaveZone = false;
  saveDialogZone = () => {
    if (this.formCreateZone.valid) {
      this.onSaveZone = true;

      if (this.dialogZone.action === 'create') {
        this.transportationService.createZone(this.formCreateZone.value)
          .then(() => {
            this.toastService.success('Se agregó una nueva zona.');
            this.toggleDialogZone('hide');
            this.onSaveZone = false;
            this.getZones()
          })
      }

      if (this.dialogZone.action === 'modify') {
        this.transportationService.updateZone(this.dialogZone.idItem, this.formCreateZone.value)
          .then(() => {
            this.toastService.success(`Se modifico la zona.`);
            this.toggleDialogZone('hide');
            this.onSaveZone = false;
            this.getZones()
          })
      }
    }
  }

  deleteDialogZone = (event: Event, id: number) => {
    this.confirmDialogService.dialog('¿Está seguro de eliminar esta zona?', 'Continuar', 'Cancelar')
      .then(() => {
        this.transportationService.deleteZone(id)
          .then(() => {
            this.toastService.success('La zona se eliminó.')
            this.getZones()
          })
          .catch((error: any) => {
            this.toastService.error(error.error.data.message)
          })
      })
      .catch(() => { })
  }




  // ------------------- Location -------------------
  public isLoadingLocations = false;

  public getLocations = async () => {
    this.isLoadingLocations = true;

    await this.transportationService.getLocations().then((response) => this.locationsList = response)

    setTimeout(() => this.isLoadingLocations = false, 500);
  }

  public formCreateLocation = this.formBuilder.group({
    name: [null, [Validators.required]],
    zone: [null, [Validators.required]],
    type: [null, [Validators.required]],
  });

  get formCreateLocationName() { return this.formCreateLocation.get('name') }
  set formCreateLocationName(value: any) { this.formCreateLocationName.setValue(value) }
  get formCreateLocationZone() { return this.formCreateLocation.get('zone') }
  set formCreateLocationZone(value: any) { this.formCreateLocationZone.setValue(value) }
  get formCreateLocationType() { return this.formCreateLocation.get('type') }
  set formCreateLocationType(value: any) { this.formCreateLocationType.setValue(value) }

  public typeLocations = [
    { label: 'Aeropuerto', value: 'AIRPORT' },
    { label: 'Hotel', value: 'HOTEL' },
    { label: 'Otro', value: 'OTHER' },
  ];

  public dialogLocation = {
    visible: false,
    title: '',
    action: 'create',
    reference: null,
    idItem: null,
  }

  toggleDialogLocation = (status: 'show' | 'hide', title: string = '', action: 'create' | 'modify' = 'create', reference: any = null) => {
    if (status === 'show') {
      this.dialogLocation.visible = true;
      this.dialogLocation.title = title;
      this.dialogLocation.action = action;
      this.dialogLocation.reference = reference;

      if (action === 'modify') {
        this.dialogLocation.idItem = reference.transportationLocationId;
        this.formCreateLocationName = reference.label
        this.formCreateLocationZone = reference.transportationZoneId
        this.formCreateLocationType = reference.type
      }
    }

    if (status === 'hide') {
      this.dialogLocation.visible = false;
      this.dialogLocation.title = '';
      this.dialogLocation.action = 'create';
      this.dialogLocation.reference = null;
      this.dialogLocation.idItem = null;
      this.formCreateLocation.reset()
      this.onSaveLocation = false;
    }
  }

  public onSaveLocation = false;
  saveDialogLocation = () => {
    if (this.formCreateLocation.valid) {
      this.onSaveLocation = true;

      if (this.dialogLocation.action === 'create') {
        this.transportationService.createLocation(this.formCreateLocation.value)
          .then(() => {
            this.toastService.success(`Se agegró una nueva ubicación.`);
            this.toggleDialogLocation('hide');
            this.onSaveLocation = false;
            this.getLocations()
          })
      }

      if (this.dialogLocation.action === 'modify') {
        this.transportationService.updateLocation(this.dialogLocation.idItem, this.formCreateLocation.value)
          .then(() => {
            this.toastService.success(`Se modifico la zona ${this.getZoneName(this.formCreateLocationZone.value)}.`);
            this.toggleDialogLocation('hide');
            this.onSaveLocation = false;
            this.getLocations()
          })
      }
    }
  }

  deleteDialogLocation = (event: Event, id: number) => {
    this.confirmDialogService.dialog('¿Está seguro de eliminar esta ubicación?', 'Continuar', 'Cancelar')
      .then(() => {
        this.transportationService.deleteLocation(id)
          .then(() => {
            this.toastService.success('La ubicación se eliminó.')
            this.getLocations()
          })
          .catch((error: any) => {
            this.toastService.error(error.error.data.message)
          })
      })
      .catch(() => { })
  }
}
